import React from 'react';

import { GroupAnimation, GroupedBlockAnimation } from '../components/animations/group-animation';
import { DELAY_OFFSET } from '../components/animations/config';
import Image from '../components/image';
import Quote from '../components/quote';

const BlockStatementCollage = (props) => {
  const { statement, source, images, caption } = props;

  return (
    <GroupAnimation as="figure" className="statement-collage-block block-content">
      <div className="statement-collage-block__statement block-theme-wrapper__accent">
        <Quote
          source={source ? source.source : null}
          quote={statement ? statement.statement : null}
        />
      </div>

      {images?.map((image, index) => (
        <GroupedBlockAnimation
          className="statement-collage-block__image-wrapper"
          delay={DELAY_OFFSET * index}
          key={index}
        >
          <Image className="statement-collage-block__image" image={image} alt={image.title} />
        </GroupedBlockAnimation>
      ))}

      {/* We still want this <figcaption> to render for layout purposes, even if the contents are empty. */}
      <figcaption className="statement-collage-block__caption block-theme-wrapper__dimmed">
        {caption ? caption.caption : null}
      </figcaption>
    </GroupAnimation>
  );
};

export default BlockStatementCollage;
