import classNames from 'classnames';
import React from 'react';

import Image from '../components/image';
import Quote from '../components/quote';

const BlockQuote = (props) => {
  const { quote, source, theme, textColor, image } = props;

  const quoteBlockClasses = classNames({
    'block-content': true,
    'quote-block': true,
    'quote-block--with-image': !!image,
  });

  return (
    <div className={quoteBlockClasses} style={{ color: textColor }}>
      {image && (
        <div className="quote-block__image-container">
          <div className="quote-block__image">
            <Image asBackground tag="span" image={image} style={{ height: '100%' }} />
          </div>
        </div>
      )}
      <div className="quote-block__quote-container">
        <Quote source={source ? source.source : null} quote={quote ? quote.quote : null} />
      </div>
    </div>
  );
};

export default BlockQuote;
