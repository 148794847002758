import classNames from 'classnames';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import blockConductor from '../blocks/block-conductor';
import BlockNewsletterSignup from '../blocks/block-newsletter-signup';
import ContactsList from '../components/contacts-list';
import Header from '../components/header';
import Image from '../components/image';
import Layout from '../components/layout';
import Link from '../components/link';
import Markdown from '../components/markdown';
import SEO from '../components/SEO';
import SectionOpener from '../sections/section-opener/section-opener';
import { resolve } from '../urls';

class SingleWhitepaperPage extends Component {
  render() {
    const { data, location, pageContext } = this.props;
    const { contentfulWhitepaper: page } = data;

    let renderedBlocks;
    if (page.content) {
      renderedBlocks = page.content.map((block, i) => blockConductor(block, i));
    }

    const pageWrapClasses = classNames({
      'page': true,
      'page--whitepaper': true,
    });

    return (
      <div className={pageWrapClasses}>
        <Layout navbarIsInverted={false}>
          <SEO
            title={page.title}
            description={page.teaserText?.teaserText}
            metadata={page.metadata}
          />
          <SectionOpener openerType="whitepaper">
            <div className="whitepaper__content-wrap">
              {page.title && <Header heading={page.title} label="Get the Whitepaper" />}
              {page.introduction && (
                <Markdown markdown={page.introduction.childMarkdownRemark.html} />
              )}
            </div>
            {page.mainImage && (
              <div className="whitepaper__img-wrap">
                <Image image={page.mainImage} alt={page.mainImage.title} />
              </div>
            )}
          </SectionOpener>
          {page.signupForm && (
            <div className="whitepaper-signup">
              <BlockNewsletterSignup {...page.signupForm} />
            </div>
          )}

          <div className="whitepaper__description-flex-wrap ">
            <div className="whitepaper__blocks-wrap">
              <>{renderedBlocks}</>
            </div>

            {page.authors && (
              <div className="whitepaper__contacts-wrap">
                <div className="content-footer__item-heading">Written by</div>
                <ContactsList contacts={page.authors} />
              </div>
            )}
          </div>

          {pageContext.relatedServiceTitle && (
            <div className="whitepaper__related-service">
              <div className="whitepaper__related-service-wrap">
                <p className="whitepaper__related-service-title">This publication is part of:</p>
                <Link
                  to={resolve('services:detail', {
                    serviceSlug: pageContext.relatedServiceSlug,
                  })}
                  className="whitepaper__related-service-link"
                >
                  {pageContext.relatedServiceTitle}
                </Link>
              </div>
            </div>
          )}
        </Layout>
      </div>
    );
  }
}

export default SingleWhitepaperPage;

export const pageQuery = graphql`
  query ($slug: String!) {
    contentfulWhitepaper(slug: { eq: $slug }) {
      title
      slug
      mainImage {
        title
        file {
          contentType
        }
        gatsbyImageData(layout: CONSTRAINED, width: 400)
      }
      document {
        title
        file {
          url
        }
      }
      introduction {
        childMarkdownRemark {
          html
        }
      }
      authors {
        name
        shortName
        active_employee
        avatar {
          title
          file {
            contentType
          }
          gatsbyImageData(layout: CONSTRAINED, width: 200, aspectRatio: 1)
        }
        position
        slug
        email
        office {
          city
        }
        description {
          description
          childMarkdownRemark {
            html
          }
        }
      }
      content {
        ... on Node {
          ... on ContentfulBlockText {
            __typename
            text {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      signupForm {
        __typename
        title
        description {
          childMarkdownRemark {
            html
          }
        }
        formActionUrl
        emailFieldName
        honeypotFieldName
        fnameFieldName
        lnameFieldName
        companyFieldName
        theme
        image {
          title
          file {
            url
          }
        }
        button_text
        consentCheckBoxes {
          consentFieldName
          label {
            childMarkdownRemark {
              html
            }
          }
          required
        }
        privacyNotice {
          childMarkdownRemark {
            html
          }
        }
      }
      teaserTitle
      teaserText {
        childMarkdownRemark {
          html
        }
      }
      metadata {
        ...Metadata
      }
    }
  }
`;

SingleWhitepaperPage.propTypes = {
  children: PropTypes.node,
  navbarIsInverted: PropTypes.bool,
};
