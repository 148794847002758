import classNames from 'classnames';
import React from 'react';
import {
  GroupAnimation,
  GroupedBlockAnimation,
  GroupedTextAnimation,
} from '../components/animations/group-animation';
import { DELAY_OFFSET } from '../components/animations/config';

const Quote = (props) => {
  const { quote, source } = props;

  const quoteClasses = classNames({
    'quote': true,
    'quote--omit': !!source,
  });

  return (
    <GroupAnimation as="blockquote" className={quoteClasses}>
      {quote && (
        <GroupedTextAnimation as="span" className="quote__quote">
          {quote}
        </GroupedTextAnimation>
      )}
      {source && (
        <GroupedBlockAnimation as="cite" className="quote__cite" delay={DELAY_OFFSET * 6}>
          {source}
        </GroupedBlockAnimation>
      )}
    </GroupAnimation>
  );
};

export default Quote;
